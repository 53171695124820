import Left from "./Left";
import Contents from "./Contents";
import Right from "./Right";
import {useState} from "react";
import Loading from "../../component/Loading";
import {accountActions} from "../../redux/actions/account";
import {connect, useSelector} from "react-redux";
import * as api from "../../api/api";
import dayjs from "dayjs";
import {calcOdds} from "../biz/Betting";

const Betting = (props) => {
    const {user} = useSelector((props) => props.account);
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState("")
    const [league, setLeague] = useState({
        id: 'soccer', list: null
    })
    const [myInfo, setMyInfo] = useState({
        myCash: 0, myPoint: 100000
    })
    const [bettingList, setBettingList] = useState([])
    const [bettingAmount, setBettingAmount] = useState(0)
    let currentPer = 1

    const onSelectLeague = (league) => {
        setLeague(league);
    }

    const onInit = () => {
        setBettingList([])
        setBettingAmount(0)
    }

    const onBettingCart = (betting) => {
        // 중복 체크 - id 와 market_id 가 일치하는 경기는 중복될 수 없다
        const isExistData = bettingList.filter(item => item.id === betting.id && item.market_id === betting.market_id)
        if (isExistData.length > 0) {
            window.alert("배팅을 진행할 수 없습니다.")
            return
        }

        setBettingList([
            ...bettingList, betting
        ])
    }

    const onBettingAmount = (amount) => {
        setBettingAmount(amount)
    }

    const onBettingRowDelete = (idx) => {
        // 현재 목록에서 해당 idx를 제거한다
        if (bettingList.length > 0) {
            const newList = bettingList.filter((_, index) => index !== idx);
            // 상태를 업데이트
            setBettingList(newList)
        }
    }

    const onBetting = () => {
        if (bettingList.length === 0) {
            window.alert("선택된 배팅내역이 없습니다.")
            return;
        }

        // 최대 100배까지만 배팅이 가능하다
        const maxPer = 100;

        // 현재 배당율 계산
        currentPer = bettingList.reduce((acc, curr) => acc * curr.odd_value, 1)

        if (currentPer > maxPer) {
            window.alert("최대 100배 까지 배팅이 가능합니다.")
            return;
        }

        console.log("### 배팅금액 :: ", bettingAmount)
        if (bettingAmount === 0) {
            window.alert("배팅 금액을 지정하세요.")
            return;
        }

        if (window.confirm("위의 내역으로 배팅하시겠어요?")) {
            setLoading(true)
            // setTimeout(() => {
            //     setLoading(false)
            //     window.alert("서버에 일시적으로 문제가 발생했습니다.\n잠시 후 다시 시도해주세요.")
            // }, 3000)
            betting();
        }
    }

    const betting = () => {
        // betting list 조립
        const list = []
        bettingList.map(item => {
            const match = {
                matchId: item.id,
                marketId: item.market_id,
                marketName: item.market_name,
                oddsName: item.odd_name,
                oddsBase: item.odds_base,
                oddsValue: item.odd_value,
                instUser: user.userId
            }
            list.push(match)
        })

        // betting header 조립
        const params = {
            bettingDt: dayjs().format('YYYYMMDD'),
            bettingUserId: user.userId,
            bettingCnt: bettingList.length,
            bettingOdds: calcOdds(bettingList),
            bettingAmount: bettingAmount,
            instUser: user.userId,
            detailList: list
        }

        api.putBetting(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                window.alert("배팅 처리가 완료되었습니다.")
                onInit()

                login()
            }
            else {
                window.alert(statusText)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const login = () => {
        const {userId, password} = user;
        const params = {
            userId: userId,
            // password: sha256(password)
            password: password
        }

        setLoading(true);
        api.login(params).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                // 로그인 타입 확인
                props.setAccount(data)
            }
            else {
                window.alert(statusText)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const CurrentContents = () => {

    }

    return (
        <div className={"flex flex-col bg-[#0b0e18] justify-between"}>
            {loading ? <Loading /> : null}
            <Left onSelectLeague={onSelectLeague} />
            <div className={"flex flex-row"}>
                <Contents props={league} title={title} onCart={onBettingCart} />
                <Right init={onInit} info={myInfo} onBetting={onBetting} onBettingRowDelete={onBettingRowDelete} bettingList={bettingList} onBettingAmount={onBettingAmount} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Betting)